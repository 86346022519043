<template>
  <base-dialog v-model="dialog" title="Removendo loja" max-width="900" use-icon-close header-color="primary" @close-dialog="cancel">
    <v-row>
      <v-col cols="4">
        <mf-text-input v-model="unit.name" disabled outlined label="Nome" class="ml-4" />
      </v-col>
      <v-col cols="4">
        <mf-text-input v-model="unit.cnpj" v-mask="'##.###.###/####-##'" disabled outlined label="CNPJ" class="mr-4" />
      </v-col>
    </v-row>
    <v-row justify="center" class="align-center">
      <v-col class="text-center">
        Remoção de lojas ainda não foi desenvolvida. Apenas lojas sem vendas atreladas e sem lojas atreladas nas plataformas podem ser deletadas. Caso realmente
        seja necessário deletar a loja, por favor entrar em contato com a equipe de BI.
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col>
        <mf-action-buttons
          class="mt-2"
          :primary-button="{
            text: 'Remover loja',
            action: save,
            isVisible: true,
            isDisabled: true,
            isLoading: saving
          }"
          :cancel-button="{
            text: 'Cancelar',
            action: cancel,
            isVisible: true,
            isDisabled: saving
          }"
        />
      </v-col>
    </v-row>
  </base-dialog>
</template>

<script>
export default {
  name: 'RemoveUnit',
  components: {
    BaseDialog: () => import('@/components/atomic-components/atoms/BaseDialog.vue')
  },
  props: {
    value: {
      type: Boolean
    },
    unit: Object,
    saveFn: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      clientUnit: {
        name: '',
        cnpj: '',
        product: ''
      },
      saving: false
    }
  },
  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    async save() {
      this.saving = true
      try {
        // Readd once implemented
        // await this.saveFn(this.unit._id)
        this.dialog = false
      } catch (err) {
        this.$alert({
          alert_message: 'Erro ao remover loja.',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      } finally {
        this.saving = false
      }
    },
    cancel() {
      this.dialog = false
    }
  }
}
</script>
