var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-dialog',{attrs:{"title":"Removendo loja","max-width":"900","use-icon-close":"","header-color":"primary"},on:{"close-dialog":_vm.cancel},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('mf-text-input',{staticClass:"ml-4",attrs:{"disabled":"","outlined":"","label":"Nome"},model:{value:(_vm.unit.name),callback:function ($$v) {_vm.$set(_vm.unit, "name", $$v)},expression:"unit.name"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('mf-text-input',{directives:[{name:"mask",rawName:"v-mask",value:('##.###.###/####-##'),expression:"'##.###.###/####-##'"}],staticClass:"mr-4",attrs:{"disabled":"","outlined":"","label":"CNPJ"},model:{value:(_vm.unit.cnpj),callback:function ($$v) {_vm.$set(_vm.unit, "cnpj", $$v)},expression:"unit.cnpj"}})],1)],1),_c('v-row',{staticClass:"align-center",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-center"},[_vm._v(" Remoção de lojas ainda não foi desenvolvida. Apenas lojas sem vendas atreladas e sem lojas atreladas nas plataformas podem ser deletadas. Caso realmente seja necessário deletar a loja, por favor entrar em contato com a equipe de BI. ")])],1),_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',[_c('mf-action-buttons',{staticClass:"mt-2",attrs:{"primary-button":{
          text: 'Remover loja',
          action: _vm.save,
          isVisible: true,
          isDisabled: true,
          isLoading: _vm.saving
        },"cancel-button":{
          text: 'Cancelar',
          action: _vm.cancel,
          isVisible: true,
          isDisabled: _vm.saving
        }}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }